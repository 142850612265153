export {}
import './Math'

if (!Number.isZero) {
  Object.defineProperty(Number, 'isZero', {
    value: function (f: number) {
      return f === 0
    },
    writable: true,
    configurable: true,
    enumerable: false,
  })
}

if (!Number.isAlmostZero) {
  Object.defineProperty(Number, 'isAlmostZero', {
    value: function (f: number, tolerence: number = Math.EPSILON) {
      return Math.abs(f) < tolerence
    },
    writable: true,
    configurable: true,
    enumerable: false,
  })
}

if (!Number.isInfinity) {
  Object.defineProperty(Number, 'isInfinity', {
    value: function (f: number) {
      return f === Infinity || f === -Infinity
    },
    writable: true,
    configurable: true,
    enumerable: false,
  })
}

if (!Number.prototype.AlmostEquals) {
  Object.defineProperty(Number.prototype, 'AlmostEquals', {
    value: function (f: number, tolerence?: number) {
      let T
      if (this == null) {
        throw new TypeError('this is null or not defined')
      }
      if (isNaN(f)) {
        throw new TypeError(`${f} is not a number`)
      }
      if (!tolerence) T = Math.EPSILON
      else T = tolerence 
      return Math.abs(this - f) < T
    },
    writable: true,
    configurable: true,
    enumerable: false,
  })
}