const floor_height = 0.5

export const layerConstant = {
  draw_type: {
    env_boundary: 'linestring',
    env_setback: 'linestring',
    env_law_north: 'linestring',
    env_law_window: 'linestring',
    env_solid: 'polygon',
    env_etc: 'linestring',
    env_surgeom_building: 'polygon',
    geom_basebox: 'polygon',
    core_conc: 'polygon',
    core_wall: 'linestring',
    core_door: 'linestring',
    core_etc: 'linestring',
    core_boundary: 'polygon',
    building_boundary: 'polygon',
    core_piloti: 'polygon',
    unit_conc: 'polygon',
    unit_wall: 'linestring',
    unit_door: 'linestring',
    unit_win: 'linestring',
    unit_fur: 'linestring',
    unit_etc: 'linestring',
    unit_boundary: 'polygon',
    geom_ground: 'polygon',
    geom_view: 'polygon',
    geom_distance: 'polygon',
    geom_3d_floor: 'polygon',
    geom_3d_evacuation: 'polygon',
    geom_3d_volume: 'polygon',
    geom_3d_win: 'linestring',
    geom_3d_win_sub: 'linestring',
    geom_3d_core: 'polygon',
    geom_3d_corridor: 'polygon',
    geom_erase: 'polygon',
    area_net: 'polygon',
    area_center: 'polygon',
    area_service: 'polygon',
    area_core: 'polygon',
    area_coverage: 'polygon',
    elem_win_main: 'linestring',
    elem_win_sub: 'linestring',
    hover: 'polygon',
    hover_fill: 'polygon',
    north_extrusion: 'multipolygon',
    regulation_window_north: 'multipolygon',
    regulation_window_south_to_lower_bdg: 'multipolygon',
    regulation_window_south: 'multipolygon',
    regulation_lawline: 'multipolygon',
  },
  order_2d: {
    env_boundary: 0,
    env_setback: 0,
    env_law_north: 0,
    env_law_window: 0,
    env_solid: 0,
    env_etc: 0,
    env_surgeom_building: 0,
    geom_basebox: 2,
    core_conc: 8,
    core_wall: 8,
    core_door: 6,
    core_etc: 5,
    core_boundary: 0.2,
    core_piloti: 8,
    unit_conc: 8,
    unit_wall: 8,
    unit_door: 7,
    unit_win: 7,
    unit_fur: 6,
    unit_etc: 5,
    unit_boundary: 0.5,
    building_boundary: 1,
    geom_ground: 8,
    geom_view: 3,
    geom_distance: 4,
    north_extrusion: 0,
    regulation_window_north: 0,
    regulation_window_south_to_lower_bdg: 1,
    regulation_window_south: 1,
    regulation_lawline: 1,
    geom_3d_floor: -1,
    geom_3d_win: -1,
    geom_3d_win_sub: -1,
    geom_3d_core: -1,
    geom_3d_evacuation: -1,
    geom_3d_volume: 10,
    area_net: -1,
    area_center: -1,
    area_service: -1,
    area_core: -1,
    area_coverage: -1,
    elem_win_main: -1,
    elem_win_sub: -1,
    hover: 10,
    hover_fill: 4,
    geom_erase: 9,
  },
  opacity_2d: {
    north_extrusion: 0.5,
    regulation_window_north: 1,
    regulation_window_south_to_lower_bdg: 1,
    regulation_window_south: 1,
    regulation_lawline: 0.05,
  },
  stroke_2d: {
    env_boundary: 'rgba(255, 0, 0)',
    // env_boundary: '#7471ff',
    env_setback: 'rgba(0, 64, 255)',
    env_law_north: 'rgba(0, 64, 255)',
    env_law_window: 'rgba(0, 64, 255)',
    env_solid: '#000000',
    env_etc: 'rgba(100,100,100,1)',
    env_surgeom_building: 'transparent',
    geom_basebox: '#000000',
    core_conc: '#000000',
    core_wall: 'rgba(90,90,90,1)',
    core_door: 'rgba(70,70,70,1)',
    core_etc: 'rgba(70,70,70,1)',
    core_boundary: 'transparent',
    core_piloti: '#000000',
    unit_boundary: 'transparent',
    building_boundary: '#000000',
    unit_conc: '#000000',
    unit_wall: 'rgba(90,90,90,1)',
    unit_door: 'rgba(70,70,70,1)',
    unit_win: 'rgba(70,70,70,1)',
    unit_fur: 'rgba(70,70,70,1)',
    unit_etc: 'rgba(70,70,70,1)',
    geom_ground: '#000000',
    geom_view: 'rgba(70,70,70,1)',
    geom_distance: 'rgba(70,70,70,1)',
    hover: 'transparent',
    hover_fill: 'transparent',
    geom_erase: 'white',
    north_extrusion: 'transparent',
    regulation_window_north: 'rgb(0, 0, 0)',
    regulation_window_south_to_lower_bdg: 'rgb(0, 0, 0)',
    regulation_window_south: 'rgb(0, 0, 0)',
    regulation_lawline: 'transparent',
    // geom_3d_volume: 'rgb(200,200,200)',
  },
  stroke_type_2d: {
    env_boundary: [3000, 1200, 800, 1200, 800, 1200],
    env_setback: [2000, 1500],
    env_law_north: [1500, 1200],
    env_law_window: [1500, 2500],
    env_solid: 0,
    env_etc: 0,
    env_surgeom_building: 0,
    geom_basebox: 0,
    core_conc: 0,
    core_wall: 0,
    core_door: 0,
    core_etc: 0,
    core_piloti: 0,
    core_boundary: 0,
    unit_boundary: 0,
    building_boundary: [800, 800],
    unit_conc: 0,
    unit_wall: 0,
    unit_door: 0,
    unit_win: 0,
    unit_fur: 0,
    unit_etc: 0,
    geom_ground: 0,
    geom_view: 0,
    geom_distance: 0,
    hover: 0,
    hover_fill: 0,
    north_extrusion: 0,
    regulation_window_north: [1000, 1000],
    regulation_window_south_to_lower_bdg: [1000, 1000],
    regulation_window_south: [1000, 1000],
    regulation_lawline: 0,
  },
  stroke_width_2d: {
    env_boundary: 500,
    env_setback: 350,
    env_law_north: 150,
    env_law_window: 150,
    env_solid: 50,
    env_etc: 30,
    env_surgeom_building: 0,
    geom_basebox: 50,
    core_conc: 50,
    core_wall: 50,
    core_door: 20,
    core_etc: 50,
    core_piloti: 50,
    core_boundary: 0,
    building_boundary: 50,
    unit_boundary: 0,
    unit_conc: 0,
    unit_wall: 50,
    unit_door: 20,
    unit_win: 50,
    unit_fur: 30,
    unit_etc: 30,
    geom_ground: 50,
    geom_view: 50,
    geom_distance: 50,
    hover: 0,
    hover_fill: 0,
    north_extrusion: 50,
    regulation_window_north: 50,
    regulation_window_south_to_lower_bdg: 50,
    regulation_window_south: 50,
    regulation_lawline: 50,
    // geom_3d_volume: 50,
  },
  fill_2d: {
    env_boundary: 'transparent',
    env_setback: 'transparent',
    env_solid: '#000000',
    env_etc: 'transparent',
    env_surgeom_building: 'rgb(220, 220, 220)',
    geom_basebox: 'transparent',
    core_conc: 'transparent',
    core_wall: 'transparent',
    core_door: 'transparent',
    core_etc: 'transparent',
    core_boundary: 'white',
    core_piloti: '#000000',
    unit_boundary: 'white',

    building_boundary: 'white',
    unit_conc: '#000000',
    unit_wall: 'transparent',
    unit_door: 'transparent',
    unit_win: 'transparent',
    unit_fur: 'transparent',
    unit_etc: 'transparent',
    geom_ground: '#000000',
    geom_view: 'transparent',
    geom_distance: 'transparent',
    hover: 'transparent',
    hover_fill: '#ffffff',
    geom_erase: 'white',
    geom_3d_volume: 'rgb(200,200,200)',
    north_extrusion: 'rgb(220, 220, 220)',
    regulation_window_north: 'transparent',
    regulation_window_south_to_lower_bdg: 'transparent',
    regulation_window_south: 'transparent',
    regulation_lawline: 'rgb(0, 64, 255)',
  },
  use_3d: {
    geom_basebox: true,
    core_conc: false,
    core_wall: false,
    core_piloti: true,
    unit_conc: true,
    unit_wall: true,
    geom_3d_floor: true,
    geom_3d_corridor: true,
    geom_3d_win: true,
    geom_3d_win_sub: true,
    geom_3d_core: true,
    geom_3d_evacuation: true,
    geom_3d_volume: true,
    core_boundary: true,
    unit_boundary: true,
    north_extrusion: true,
    regulation_window_north: true,
    regulation_window_south_to_lower_bdg: true,
    regulation_window_south: true,
    regulation_lawline: true,
  },
  z_move_3d: {
    geom_basebox: (h) => 0,
    core_conc: (h) => 0,
    core_wall: (h) => 0,
    core_piloti: (h) => 0,
    unit_conc: (h) => 0,
    unit_wall: (h) => 0,
    geom_3d_floor: (h) => -floor_height,
    geom_3d_corridor: (h) => h - floor_height,
    geom_3d_win: (h) => 0,
    geom_3d_win_sub: (h) => 0,
    geom_3d_core: (h) => 0,
    geom_3d_evacuation: (h) => -floor_height,
    geom_3d_volume: (h) => -floor_height,
    core_boundary: (h) => 0,
    unit_boundary: (h) => -floor_height,
  },
  ext_height_3d: {
    geom_basebox: (h) => h,
    core_conc: (h) => h - floor_height,
    core_wall: (h) => h - floor_height,
    core_piloti: (h) => h,
    geom_3d_core: (h) => h,
    unit_conc: (h) => h - floor_height,
    unit_wall: (h) => h - floor_height,
    geom_3d_floor: (h) => floor_height,
    geom_3d_corridor: (h) => floor_height,
    geom_3d_win: (h) => h - floor_height,
    geom_3d_win_sub: (h) => h - floor_height,
    geom_3d_evacuation: (h) => h,
    geom_3d_volume: (h) => h,
    core_boundary: (h) => 2 * h,
    unit_boundary: (h) => floor_height,
  },
  fill_3d: {
    geom_basebox: 'white',
    core_conc: 'white',
    core_wall: 'white',
    core_piloti: 'white',
    unit_conc: 'white',
    unit_wall: 'white',
    geom_3d_floor: 'white',
    geom_3d_corridor: 'white',
    geom_3d_win: '#5b588a',
    geom_3d_win_sub: '#5b588a',
    geom_3d_evacuation: '#aaaaaa',
    geom_3d_volume: 'white',
    core_boundary: 'white',
    unit_boundary: 'white',
    north_extrusion: 'rgb(220, 220, 220)',
    regulation_window_north: 'rgb(100, 100, 100)',
    regulation_window_south_to_lower_bdg: 'rgb(100, 100, 100)',
    regulation_window_south: 'rgb(100, 100, 100)',
    regulation_lawline: 'rgb(0, 64, 255)',
  },
  opacity_3d: {
    geom_basebox: 1,
    core_conc: 1,
    core_wall: 1,
    core_piloti: 1,
    unit_conc: 1,
    unit_wall: 1,
    geom_3d_floor: 1,
    geom_3d_corridor: 1,
    geom_3d_win: 1,
    geom_3d_win_sub: 1,
    geom_3d_evacuation: 1,
    geom_3d_volume: 1,
    core_boundary: 1,
    unit_boundary: 1,
    north_extrusion: 0.1,
    regulation_window_north: 0.3,
    regulation_window_south_to_lower_bdg: 0.3,
    regulation_window_south: 0.3,
    regulation_lawline: 0.1,
  },
  use_roof_3d: {
    geom_3d_floor: true,
    geom_3d_corridor: true,
  },
}
export const colorConstant = [
  '#9DFCF8',
  '#7AEAE5',
  '#3DD0DA',
  '#5CD6FD',
  '#79B9FC',
  '#6F9BFF',
  '#7180FF',
  '#9D8DFF',
  '#C79FFF',
  '#E0A2F4',
  '#F2B2E3',
  '#FFCEFF',
  '#FFCECB',
  '#FFBBAC',
  '#FF9999',
  '#FF7376',
  '#F44F59',
]
