import { createRouter, createWebHistory } from 'vue-router'

import UserLayout from '/Layouts/UserLayout.vue'
import ProjectLayout from '/Layouts/ProjectLayout.vue'

// import LoginPage from '/Pages/LoginPage.vue'
// import ProjectPage from '/Pages/ProjectPage.vue'

const LoginPage = () => import('/Pages/LoginPage.vue')
const ProjectPageV1 = () => import('/v1/pages/ProjectPage.vue')
const ProjectPageENV1 = () => import('/v1/pages/ProjectPageEN.vue')

const ProjectPage = () => import('/Pages/ProjectPage.vue')

const TestView = () => import('/Views/Test/TestView.vue')
const Test3DView = () => import('/Views/Test/3DTest.vue')

const routes: Array<any> = [
  {
    path: '/',
    name: 'UserLayout',
    component: UserLayout,
    children: [
      {
        path: '/',
        name: 'LoginPage',
        component: LoginPage,
      },
    ],
  },
  {
    path: '/project',
    name: 'ProjectLayout',
    component: ProjectLayout,
    props: true,
    children: [
      {
        path: '/project/v1/a1f0825d-3925-4252-a4ae-4ea6d1ea0477',
        name: 'ProjectPage-en-v1-noryangjin',
        component: ProjectPageENV1,
        props: { uuid: 'a1f0825d-3925-4252-a4ae-4ea6d1ea0477' },
      },
      {
        path: '/project/v1/9005dad3-b00c-45d6-9e32-009df641cfdd',
        name: 'ProjectPage-en-v1-sokcho',
        component: ProjectPageENV1,
        props: { uuid: '9005dad3-b00c-45d6-9e32-009df641cfdd' },
      },
      {
        path: '/project/v1/:uuid',
        name: 'ProjectPage-v1',
        component: ProjectPageV1,
        props: true,
      },
      {
        path: '/project/:uuid',
        name: 'ProjectPage',
        component: ProjectPage,
        props: true,
      },
    ],
  },
  {
    path: '/test',
    name: 'testview',
    component: TestView,
  },
  {
    path: '/test3d',
    name: 'test3d',
    component: Test3DView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
