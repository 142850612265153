import { defineModule } from 'direct-vuex'
import { moduleActionContext, moduleGetterContext } from '/Store/index'
import { state, UserMutationTypes, UserActionTypes } from './userTypes'
import type { userState, Getters, User, ProjectListType } from './userTypes'
import ApiService from '/Services/v2/api'
import ApiServiceLegacy from '/Services/v1/api'
import { parseProjectList } from '/Utils/DataParser'

const userStore = defineModule({
  namespaced: true,
  state: (): userState => {
    return state
  },
  getters: {
    isLoggedIn(...args): boolean {
      const { state } = userGetterContext(args)
      return !!state.user
    },
  },
  mutations: {
    [UserMutationTypes.LOGIN](
      state: userState,
      payload: User & { accessToken: string }
    ) {
      state.user = payload
    },
    [UserMutationTypes.LOGOUT](state: userState) {
      state.user = null
    },
    [UserMutationTypes.SET_USER](state: userState, payload: User) {
      state.user = payload
    },
    [UserMutationTypes.SET_PROJECTS](
      state: userState,
      payload: ProjectListType[]
    ) {
      state.projects = parseProjectList(payload)
    },
  },
  actions: {
    async [UserActionTypes.LOGIN](context, payload) {
      const { commit } = userActionContext(context)
      try {
        await ApiServiceLegacy.LOGIN(payload)
        const userLoginRes = await ApiService.LOGIN(payload)

        // console.log(userLoginRes)
        // const userLoginData = userLoginRes.data
        // @ts-ignore
        // if (userLoginData.ok) {
        //   const accessToken = userLoginData.token
        //   window.localStorage.setItem('x-access-token', accessToken)
        //   // commit.SET_USER(userLoginData.body.data)
        // } else {
        //   // TODO: SET ERROR (Auth failed)
        // }
      } catch (err) {
        console.log(err.response)
        // TODO: SET ERROR (Internal)
      }
    },
    async [UserActionTypes.LOGOUT](context) {
      const { commit } = userActionContext(context)
      localStorage.removeItem('x-jwt-token')
      localStorage.removeItem('x-access-token')
      commit.LOGOUT()
    },
    async [UserActionTypes.GET_USER](context) {
      const { commit } = userActionContext(context)
      try {
        const getUserRes = await ApiService.GET_USER()
        if (getUserRes.data.success) {
          commit.SET_USER(getUserRes.data.body.data)
        }
      } catch (err) {
        // TODO: SET ERROR(Internal)
      }
    },
    async [UserActionTypes.GET_PROJECT_LIST](context) {
      const { commit } = userActionContext(context)
      try {
        const getProjectListRes = await ApiService.GET_PROJECT_LIST()
        console.log(getProjectListRes)
        if (getProjectListRes.data.success) {
          commit.SET_PROJECTS(getProjectListRes.data.body.data)
        }
      } catch (err) {
        // TODO: SET ERROR(Internal)
      }
    },
  },
})

export default userStore
const userGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, userStore)
const userActionContext = (context: any) =>
  moduleActionContext(context, userStore)
