import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { createDirectStore, GettersImpl } from 'direct-vuex'
import UserStore from './modules/user/user'
import V1Store from './modules/v1/project'
import V2Store from './modules/v2/project'

const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

type RootGetters = typeof UserStore.getters &
  typeof V1Store.getters &
  typeof V2Store.getters
type RootState = {
  user: typeof UserStore.state
  v1: typeof V1Store.state
  v2: typeof V2Store.state
}
// plugins.push(createPersistedState({ storage: window.localStorage }))

const getters = {} as RootGetters & GettersImpl<RootState, RootGetters>

const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext,
} = createDirectStore({
  plugins,
  modules: {
    user: UserStore,
    v1: V1Store,
    v2: V2Store,
  },
  getters,
})

// Export the direct-store instead of the classic Vuex store.
export default store

// The following exports will be used to enable types in the
// implementation of actions and getters.
export {
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext,
}

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store

declare module 'vuex' {
  interface Store<S> {
    // @ts-ignore
    direct: AppStore
  }
}
