import client from './client'
const { req, apiClient } = client

export default {
  LOGIN(payload) {
    return req(apiClient.post('users/signin', payload))
  },
  GET_USER() {
    return req(apiClient.get('users/me'))
  },
  GET_PROJECT_LIST() {
    return req(apiClient.get('projects'))
  },
  GET_PROJECT({ projectId }) {
    return req(apiClient.get(`projects/${projectId}`))
  },
  FETCH_BUILDING_NEIGHBORS(projectId) {
    return req(apiClient.get(`projects/sur/${projectId}`))
  },
  GET_CASE_LIST({ projectId }) {
    return req(apiClient.get(`solutions/${projectId}`))
  },
  GET_CASE_PRICE_LIST({ projectId }) {
    return req(apiClient.get(`solutions/price/${projectId}`))
  },
  GET_CASE({ projectId, caseId }) {
    console.log(projectId)
    console.log(caseId)
    return req(apiClient.get(`solutions/${projectId}/${caseId}`))
  },
  GET_CASE_REP(projectId, repCaseList) {
    return req(apiClient.post(`solutions/rep/${projectId}`, repCaseList))
  },
  TOGGLE_LIKED_CASE({ projectId, caseId }) {
    return req(apiClient.put(`solutions/like/${projectId}/${caseId}`))
  },
  UPDATE_PROJECT_PRICE({ projectId, payload }) {
    return req(apiClient.patch(`projects/price/${projectId}`, payload))
  },
  DOWNLOAD_XSLX(payload) {
    return req(apiClient.post(`download/xlsx`, payload))
  },
  DOWNLOAD_DXF({ projectId, caseId, payload }) {
    return req(apiClient.post(`download/dxf/${projectId}/${caseId}`, payload))
  },
}
