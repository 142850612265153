import axios from 'axios'
import { AxiosPromise, AxiosResponse } from 'axios'

const baseURL = process.env.NODE_ENV === 'production' ? '/api/v2' : '/api/v2'

const apiClient = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json, x-jwt-token',
    // 'Accept-Encoding': 'gzip',
    'Content-type': 'application/json',
  },
})

export type ApiReturnType = {
  success: boolean
  message: string
  errors: any
  body: any
}

const req = (reqPromise): AxiosPromise<ApiReturnType> => {
  return reqPromise
    .then((res) => {
      res.config.data = {}
      return res
    })
    .catch((err) => {
      if (err.response) err.message = err.response.data.message
      return err
    })
}

export default {
  req,
  apiClient,
}

apiClient.interceptors.request.use(
  function (config) {
    if (localStorage.getItem('x-jwt-token')) {
      config.headers['x-jwt-token'] = localStorage.getItem('x-jwt-token')
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  function (response: AxiosResponse<ApiReturnType>) {
    if (response.data.success) {
      const accessToken = response.data.body?.token
      if (accessToken) {
        window.localStorage.setItem('x-jwt-token', accessToken)
      }
      //TODO: HandleSuccess
    } else {
      // TODO: HandleFailure
    }
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
