import client from './client'
const { req, apiClient } = client

export default {
  sayHello() {
    return req(apiClient.get('test/hello'))
  },
  LOGIN(payload) {
    return req(apiClient.post('auth/login', payload))
  },
  GET_USER() {
    return req(apiClient.get('user'))
  },
  GET_PROJECT_LIST() {
    return req(apiClient.get('project'))
  },
  GET_PROJECT({ projectId }) {
    return req(apiClient.get(`project/${projectId}`))
  },
  GET_CASE_REP({ projectId }) {
    return req(apiClient.get(`project/${projectId}/rep`))
  },
  GET_CASE({ projectId, caseId }) {
    return req(apiClient.get(`project/${projectId}/${caseId}`))
  },
  TOGGLE_LIKED_CASE({ projectId, caseId }) {
    return req(apiClient.put(`project/${projectId}/${caseId}/like`))
  },
  GET_LIKED_CASE_LIST({ projectId }) {
    return req(apiClient.get(`project/${projectId}/like`))
  },
  TOGGLE_REP_CASE({ projectId, caseId }) {
    return req(apiClient.put(`project/${projectId}/${caseId}/rep`))
  },
  FILTER_CASE({ projectId, payload }) {
    return req(apiClient.post(`project/${projectId}/filter`, payload))
  },
  GET_CASE_LIST({ projectId }) {
    return req(apiClient.get(`project/${projectId}/case`))
  },
  GET_CASE_FROM_INDEX({ projectId, payload }) {
    return req(apiClient.post(`project/${projectId}/case`, payload))
  },
  FETCH_CASE_GEOMETRY(projectId, payload) {
    return req(apiClient.post(`project/${projectId}/case/geometry`, payload))
  },
  GET_XLSX_KR(payload) {
    return req(apiClient.post(`case/download/xlsx/kr`, payload))
  },
  GET_XLSX_EN(payload) {
    return req(apiClient.post(`case/download/xlsx/en`, payload))
  },
  GET_XLSX_TEMP(payload) {
    return req(apiClient.post(`case/download/xlsx/temp`, payload))
  },
  QUEUE_DOWNLOAD_ITEM({
    projectId,
    caseId,
    lang = 'KR',
  }: {
    projectId: string
    caseId: string
    lang: 'EN' | 'KR'
  }) {
    return req(apiClient.get(`case/download/${projectId}/${caseId}/${lang}`))
  },
  UPDATE_PROJECT_PRICE_FORMAT({ projectId, payload }) {
    return req(apiClient.put(`/project/${projectId}/price_format`, payload))
  },
  GET_DISPLAY_CASE({ projectId }) {
    return req(apiClient.get(`project/${projectId}/display_case`))
  },
  FETCH_BUILDING_NEIGHBORS(projectId, payload) {
    return req(apiClient.post(`project/${projectId}/neighbors`, payload))
  },
}
