type optionType = {
  round?: number
  rate?: 'KR' | 'US'
}
import { pad } from '/Utils/index'

const defaultOption: optionType = {
  round: 0,
  rate: 'KR',
}

export const getComma = (v: number | string) => {
  if (isNaN(+v)) return v
  let number = v.toString()
  if (number.includes('.')) {
    const splitted = number.split('.')
    return `${splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${splitted[1]}`
  } else return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getValue = (v: string) => {
  let number = v.replace(/,/g, '')
  if (isNaN(+number)) return number
  return number
}

export const formatFileSize = (size: number) => {
  const scale = 1024
  const orders = ['GB', 'MB', 'KB', 'Bytes']
  let max = Math.pow(scale, orders.length - 1)
  for (const order of orders) {
    if (size > max) {
      return order === 'Bytes'
        ? `${size} Bytes`
        : `${(size / max).toFixed(2)} ${order}`
    }
    max /= scale
  }
  return '0 Bytes'
}

export const getPrecision = (v: number, round: number = 0): string => {
  if (!round) return `${Math.round(v)}`
  else return (Math.round(v * Math.pow(10, round)) / 10 ** round).toFixed(round)
}

export const far = (v, options = defaultOption) => {
  const { rate, round } = options
  return `${getPrecision(v / 100, round)}`
}

export const price = (v, options = defaultOption) => {
  const { rate, round } = options
  const offset = 3
  const priceTextTable = {
    US: ['K', 'M', 'B', 'T', 'Qa', 'Qi'],
  }
  const priceSymbolTable = {
    US: '$',
  }

  // 달러 기준 환율
  const exchangeRate = 1150

  let temp = Math.abs(v) / exchangeRate
  let idx = -1
  while (temp > Math.pow(10, offset)) {
    temp /= Math.pow(10, offset)
    idx += 1
  }
  if (idx >= 2) {
    return `${v < 0 ? '-' : ''}${
      priceSymbolTable['US']
    }${temp.toString().substring(0, 1)}${
      priceTextTable['US'][idx]
    } ${getPrecision(+(temp * 1000).toString().substring(1), 0)}${
      priceTextTable['US'][idx - 1]
    }`
  } else {
    return idx === -1
      ? `${v < 0 ? '-' : ''}${priceSymbolTable['US']}${getPrecision(
          temp,
          round
        )}`
      : `${v < 0 ? '-' : ''}${priceSymbolTable['US']}${getPrecision(
          temp,
          round
        )} ${priceTextTable['US'][idx]}`
  }
}

export const price3 = (v, options = defaultOption) => {
  const isMinus = v < 0
  const { rate, round } = options
  const offset = 3
  const priceTextTable = {
    US: ['K', 'M', 'B', 'T', 'Qa', 'Qi'],
    KR: ['천', '백만', '십억', '조', '천조'],
  }
  const priceSymbolTable = {
    US: '$',
    KR: '',
  }
  let temp = Math.abs(v)
  if (rate === 'US') {
    temp /= 1339.7
  }
  let idx = -1
  while (temp > Math.pow(10, offset)) {
    temp /= Math.pow(10, offset)
    idx += 1
  }

  return idx === -1
    ? `${isMinus ? '-' : ''}${priceSymbolTable[rate]}${getPrecision(temp, 1)}`
    : `${isMinus ? '-' : ''}${priceSymbolTable[rate]}${getPrecision(temp, 1)} ${
        priceTextTable[rate][idx]
      }`
}

// export const price4 = (v, options = defaultOption) => {
//   let inputNumber = v < 0 ? false : v
//   let unitWords = ['', '만', '억', '조', '경']
//   let splitUnit = 10000
//   let splitCount = unitWords.length
//   let resultArray = []
//   let resultString = ''

//   for (let i = 0; i < splitCount; i++) {
//     let unitResult =
//       (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i)
//     // @ts-ignore
//     unitResult = pad(Math.round(unitResult), 4)
//     // @ts-ignore
//     if (+unitResult >= 0 && unitResult != '0000') {
//       resultArray[i] = unitResult
//     }
//   }

//   for (var i = 0; i < resultArray.length; i++) {
//     if (!resultArray[i] && i != 0) continue
//     resultString = String(resultArray[i]) + unitWords[i] + ' ' + resultString
//   }
//   const resultSplit = resultString.split(' ')
//   let res = ''
//   if (resultString.includes('조')) {
//     res = resultSplit.slice(0, 2).join(' ')
//   } else {
//     const firstMatched = resultString.replace(/[0-9\s]/g, '').charAt(0)
//     const firstIdx = unitWords.indexOf(firstMatched)
//     if (firstIdx < 1) {
//       res = resultString
//     } else {
//       const numSplit = resultString.replace(/[^0-9\s]/g, '').split(' ')
//       if (numSplit.length === 1) {
//         res = `${+numSplit[0]}.0${firstMatched}`
//       } else {
//         const v = pad(Math.round(+numSplit[1] / 1000) * 1000, 4)
//         res = `${+numSplit[0]}.${+v.toString().charAt(0)}${firstMatched}`
//       }
//     }
//   }
//   if (v < 0) {
//     res = '-' + res
//   }
//   return res
// }

// def myformat(value):
//     if value < 0:
//         return f"-{myformat(-value)}"
//     unit_words = ["", "만", "억", "조", "경", ]
//     split_unit = 10000
//     unit_idx = 0
//     len_unit_words = len(unit_words)
//     while value >= split_unit and unit_idx < len_unit_words - 1:
//         value /= split_unit
//         unit_idx += 1
//     word = unit_words[unit_idx]
//     return f"{round(value, 1)} {word}원"

export const price4 = (v, options = defaultOption) => {
  if (v < 0) {
    return `-${price4(-v, options)}`
  }
  let value = v
  const unitWords = ['', '만', '억', '조', '경']
  const splitUnit = 10000
  let unit_idx = 0
  const lenUnitWords = unitWords.length
  while (value >= splitUnit && unit_idx < lenUnitWords) {
    value /= splitUnit
    unit_idx += 1
  }
  const word = unitWords[unit_idx]
  return `${value.toFixed(1)} ${word}`
}

export const percent = (v, options = defaultOption) => {
  const round = options.round
  return `${getPrecision(v * 100, round)}%`
}

export const string = (v, options = defaultOption) => {
  return `${v}`
}

export const area = (v, options = defaultOption) => {
  const { round, rate } = options
  const val = getComma(getPrecision(v, round))
  return rate === 'KR' ? `${val}m²` : `${val}m²`
}

export const area_ft = (v, options = defaultOption) => {
  const { round, rate } = options
  const m2_to_ft2 = 10.76391
  const val = getComma(getPrecision(v * m2_to_ft2, round))
  return `${val}ft²`
}

export const length = (v, options = defaultOption) => {
  const { round, rate } = options
  const val = getComma(getPrecision(v, round))
  return rate === 'KR' ? `${val}m` : `${val}ft`
}

export const getOptions = (options, position: 'data' | 'round') => {
  return {
    round: options[`round_${position}`] ?? 0,
    rate: options.rate ?? 'KR',
  }
}

export default {
  far,
  price,
  price4,
  price3,
  default: (v, ...args) => v,
  getPrecision,
  getComma,
  getValue,
  percent,
  string,
  area,
  area_ft,
  length,
  getOptions,
}
