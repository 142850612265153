import { defineModule } from 'direct-vuex'
import { moduleActionContext, moduleGetterContext } from '/Store/index'
import { state, ProjectMutationTypes, ProjectActionTypes } from './projectTypes'
import type {
  projectState,
  Getters,
  TabType,
  dataFormatCell,
} from './projectTypes'
import ApiService from '/Services/v2/api'

import { parseCase, parseCaseGeom } from '/Utils/DataParser'
import { getPrecision } from '/Utils/ValueFormatter'
import { colorConstant } from '/Constants/layerConstant'
import cloneDeep from 'lodash.clonedeep'
import { saveAs } from 'file-saver'

const initialState = cloneDeep(state)

const projectStore = defineModule({
  namespaced: true,
  state: (): projectState => {
    return state
  },
  getters: {
    intervalInformation(...args): any {
      const { state } = projectGetterContext(args)
      if (!state.project) return {}
      return state.project.project_format.project_area_group_format.mapOp(
        (k, v) => {
          return {
            ...v,
          }
        }
      )
      // const map = {}
      // const intervalKeys = Object.keys(
      //   state.project.project_format.project_unit_price_format.price
      // )
      // const shift = Math.floor(colorConstant.length / intervalKeys.length)
      // for (let i = 0; i < intervalKeys.length; i++) {
      //   map[intervalKeys[i]] = {
      //     color: colorConstant[i * shift],
      //     name: state.project.data.project_data_format[intervalKeys[i]].title,
      //     key: intervalKeys[i],
      //     name_short:
      //       state.project.data.project_unit_price_format.price[intervalKeys[i]]
      //         .name_short,
      //     name_mid:
      //       state.project.data.project_unit_price_format.price[intervalKeys[i]]
      //         .name,
      //     unit_price:
      //       state.project.data.project_unit_price_format.price[intervalKeys[i]]
      //         .value,
      //     unit_cost:
      //       state.project.data.project_unit_price_format.cost[intervalKeys[i]]
      //         .value,
      //   }
      // }
    },
    normalizedMicroFormat(...args): any {
      const { state } = projectGetterContext(args)
      if (!state.project) return null

      const microFormat =
        state.project.project_format.project_micro_format.reduceOp(
          (p, cv, ck) => {
            const { options, ...curr } = cv
            p.push({ ...options, ...curr })
            return p
          },
          []
        )
      const [selection_unit, unit_type_table, unit_price_table] = [
        'selection_unit',
        'unit_type_table',
        'unit_price_table',
      ].map((key) => {
        const dt = microFormat.filter((f) => f[key])
        return dt
          .sort((a, b) => a[key] - b[key])
          .map((f) => {
            return {
              key: f.key,
              round: f.round,
              formatter: f.formatter,
              index: f[key],
              name: f.name,
            }
          })
      })

      return {
        selection_unit,
        unit_type_table,
        unit_price_table,
      }
    },
    projectId(...args): string {
      const { state } = projectGetterContext(args)
      return state.project.uuid
    },
  },
  mutations: {
    [ProjectMutationTypes.TOGGLE_TAB](state: projectState, payload: TabType) {
      state.tab = payload
    },
    [ProjectMutationTypes.SET_PROJECT](state: projectState, payload) {
      state.project = payload
    },
    [ProjectMutationTypes.SET_CASE_REP](state: projectState, payload) {
      const caseRep = parseCase(payload.caseItem, payload.row, state.project)
      state.caseRep = caseRep
    },
    [ProjectMutationTypes.SET_SELECTED_CASE](state: projectState, payload) {
      const selectedCase = parseCase(
        payload.caseItem,
        payload.row,
        state.project
      )
      state.selectedCase = selectedCase
    },
    [ProjectMutationTypes.TOGGLE_MAP_MODE](
      state: projectState,
      payload: '2D' | '3D' = null
    ) {
      if (payload) state.mapMode = payload
      else if (state.mapMode === '3D') state.mapMode = '2D'
      else state.mapMode = '3D'
    },
    [ProjectMutationTypes.UPDATE_PROJECT_MINMAX](
      state: projectState,
      payload: Record<string, [number, number]>
    ) {
      const dataFormat = state.project.project_format.project_data_format
      for (const key in dataFormat) {
        const [min, max] = payload[key].map((e) => e)
        dataFormat[key].options = {
          ...dataFormat[key].options,
          min,
          max,
        }
        dataFormat[key] = {
          ...dataFormat[key],
          value: [min, max],
        }
      }
      state.project.project_format = {
        ...state.project.project_format,
        project_data_format: dataFormat,
      }
    },
    [ProjectMutationTypes.INIT_NORMALIZED_DATA_FORMAT](state: projectState) {
      if (!state.project) {
        return
      }
      const data_format = state.project.project_format.project_data_format
      const format = Object.values(data_format).map((f: dataFormatCell) => {
        const round = +f.options.round_data
        f.options.min = +getPrecision(f.options.min, round)
        f.options.max = +getPrecision(f.options.max, round)

        f.value = [f.options.min, f.options.max]
        return f
      })

      const [
        selectionLongFiltered,
        selectionFiltered,
        sliderInfoFiltered,
        bigFiltered,
        caseOverviewFiltered,
        excelExportFiltered,
      ] = [
        'selection_long',
        'selection',
        'slider_info',
        'big',
        'case_overview',
        'excel_export',
      ].map((key) => {
        return format
          .filter((f) => f.options[key])
          .sort((a, b) => a.options[key] - b.options[key])
      })

      const sliderUseFiltered = sliderInfoFiltered.filter(
        (f) => f.value[0] - f.value[1] !== 0
      )
      const caseOverviewGrouped = caseOverviewFiltered.reduce((p, c) => {
        if (!c.options.case_overview_group) return p
        if (p[c.options.case_overview_group]) {
          p[c.options.case_overview_group].push(c)
        } else {
          p[c.options.case_overview_group] = [c]
        }
        return p
      }, {})
      state.normalizedDataFormat = {
        overview: selectionFiltered.concat(bigFiltered),
        selectionCase: selectionFiltered.concat(bigFiltered),
        selectionLong: selectionLongFiltered.concat(bigFiltered),
        sliderUse: sliderUseFiltered,
        caseOverview: caseOverviewFiltered,
        caseOverviewGrouped,
        excelExport: excelExportFiltered,
      }
      console.log(state.normalizedDataFormat)
    },
    [ProjectMutationTypes.TOGGLE_SPINNER](state: projectState, flag) {
      state.needsSpinner = flag
    },
    [ProjectMutationTypes.RESET_PROJECT_STORE](state: projectState) {
      for (const key in state) {
        state[key] = cloneDeep(initialState)[key]
      }
    },
    [ProjectMutationTypes.ADD_DOWNLOAD_QUEUE](
      state: projectState,
      caseId: any
    ) {
      state.downloadQueue.set(caseId, {
        status: 'pending',
        url: null,
        error: null,
        type: 'dxf',
      })
      state.showDownloadQueue = true
    },
    [ProjectMutationTypes.SET_DOWNLOAD_FULFILLED](
      state: projectState,
      payload: {
        caseId: any
        href: string
      }
    ) {
      const currentQueue = state.downloadQueue.get(payload.caseId)
      if (currentQueue) {
        currentQueue.status = 'fulfilled'
        currentQueue.url = payload.href
        state.downloadQueue.set(payload.caseId, currentQueue)
      }
    },
    [ProjectMutationTypes.REMOVE_DOWNLOAD_QUEUE](
      state: projectState,
      caseId: any
    ) {
      state.downloadQueue.delete(caseId)
    },
    [ProjectMutationTypes.ERROR_DOWNLOAD_QUEUE](
      state: projectState,
      payload: {
        caseId: string
        error: string
      }
    ) {
      const currentQueue = state.downloadQueue.get(payload.caseId)
      if (currentQueue) {
        currentQueue.status = 'failed'
        currentQueue.error = payload.error
        state.downloadQueue.set(payload.caseId, currentQueue)
      }
    },
    [ProjectMutationTypes.TOGGLE_CURRENT_CASE_STATUS](
      state: projectState,
      flag: 0 | 1 | 2
    ) {
      state.currentCaseStatus = flag
    },
    [ProjectMutationTypes.TOGGLE_DOWNLOAD_QUEUE](
      state: projectState,
      flag: boolean
    ) {
      state.showDownloadQueue = flag
    },
    [ProjectMutationTypes.UPDATE_PRICE_FORMAT](state: projectState, payload) {
      if(state.project?.project_data) {
        state.project.project_data.project_parameters = payload
      }
    },
  },
  actions: {
    async [ProjectActionTypes.GET_PROJECT](context, payload: string) {
      const { commit } = projectActionContext(context)
      try {
        const getProjectRes = await ApiService.GET_PROJECT({
          projectId: payload,
        })
        console.log(getProjectRes.data.body.data)
        if (getProjectRes.data.success) {
          const { land_geom, ...project } = getProjectRes.data.body.data
          commit.SET_PROJECT(project)
        } else {
          return Promise.reject(getProjectRes)
        }
      } catch (err) {
        //TODO: handle ERROR
        console.log(err)
        return Promise.reject(err)
      }
    },
    async [ProjectActionTypes.GET_CASE_LIST](context, payload: string) {
      const { commit } = projectActionContext(context)

      try {
        console.time('getParcoordCaseList')
        const getParcoordCaseListRes = await ApiService.GET_CASE_LIST({
          projectId: payload,
        })
        console.timeEnd('getParcoordCaseList')
        console.log(getParcoordCaseListRes)
        if (getParcoordCaseListRes.data.success) {
          return getParcoordCaseListRes.data.body.data
        } else {
          return Promise.reject(getParcoordCaseListRes)
        }
      } catch (err) {
        //TODO: handle ERROR
        console.log(err)
        return Promise.reject(err)
      }
    },
    async [ProjectActionTypes.GET_CASE_REP](context, payload) {
      const { commit } = projectActionContext(context)
      try {
        const getCaseRepRes = await ApiService.GET_CASE_REP({
          projectId: payload,
        })
        if (getCaseRepRes.data.success) {
          return getCaseRepRes.data.body.data
        } else {
          return Promise.reject(getCaseRepRes)
        }
      } catch (err) {
        //TODO: handle ERROR
        return Promise.reject(err)
      }
    },
    [ProjectActionTypes.QUEUE_DOWNLOAD_ITEM](context, payload) {
      const { commit } = projectActionContext(context)
      commit.ADD_DOWNLOAD_QUEUE(payload.caseId)
      ApiService.DOWNLOAD_DXF(payload)
        .then((res) => {
          const url = res.data.body.data
          if (url == null) {
            throw new Error()
          } else {
            const a = document.createElement('a')
            a.href = res.data.body.data
            document.body.appendChild(a)
            a.click()
            a.remove()
            const p = {
              caseId: payload.caseId,
              href: res.data.body.data,
            }
            commit.SET_DOWNLOAD_FULFILLED(p)
          }
        })
        .catch((err) => {
          commit.ERROR_DOWNLOAD_QUEUE({
            caseId: payload.caseId,
            error: 'Internal Server Error',
          })
          console.log(err)
        })
    },
  },
})

export default projectStore
const projectGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, projectStore)
const projectActionContext = (context: any) =>
  moduleActionContext(context, projectStore)
