<template>
  <div class="layout-project fullsize">
    <!-- <navigation-bar /> -->
    <router-view v-slot="{ Component }">
      <transition name="fade-view" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script setup name="ProjectLayout" lang="ts">
// @ts-ignore
import Store from '/Store/index'
import { onBeforeUnmount, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()

onBeforeUnmount(() => {
  Store.commit.v1.RESET_PROJECT_STORE()
  Store.commit.v2.RESET_PROJECT_STORE()
})

onBeforeMount(async () => {
  await Store.dispatch.user.GET_USER()
  if (!Store.getters.user.isLoggedIn) {
    Store.dispatch.user.LOGOUT()
    const currentPath = router.currentRoute.value.fullPath
    if (currentPath !== '/') router.push('/')
  }
})
</script>

<style lang="scss" scoped>
.layout-project {
  @include flex();
  @include relative();
}
</style>
