import {
  moduleActionContext,
  rootActionContext,
  moduleGetterContext,
  rootGetterContext,
} from '/Store/index'

/*------------ STATE -------------*/

export type TabType = {
  key: string
  name: string
}

export type downloadQueueItem = {
  status: 'pending' | 'failed' | 'fulfilled'
  url: string
  error: any
  type: 'dxf' | 'xlsx'
}

export type dataFormatCell = {
  key: string
  name: string
  value?: [number, number]
  options: Record<string, any>
}

export type projectState = {
  tab: TabType
  project: any
  caseRep: any
  selectedCase: any
  mapMode: '3D' | '2D'
  normalizedDataFormat: {
    overview?: dataFormatCell[]
    selectionCase?: dataFormatCell[]
    selectionLong?: dataFormatCell[]
    sliderUse?: dataFormatCell[]
    caseOverview?: dataFormatCell[]
    caseOverviewGrouped?: Record<string, dataFormatCell[]>
    excelExport?: dataFormatCell[]
  }
  needsSpinner: boolean
  currentCaseStatus: 0 | 1 | 2
  downloadQueue: Map<string, downloadQueueItem>
  showDownloadQueue: boolean
}
export const state: projectState = {
  tab: {
    key: 'basic',
    name: '간단히 보기',
  },
  project: null,
  caseRep: null,
  selectedCase: null,
  mapMode: '3D',
  normalizedDataFormat: {},
  needsSpinner: false,
  downloadQueue: new Map(),
  currentCaseStatus: 0,
  showDownloadQueue: false,
}

/*------------ GETTERS -------------*/

export type Getters = {
  intervalInformation(state: projectState): any
  normalizedMicroFormat(state: projectState): any
  projectId(state: projectState): string
}

/*------------ MUTATIONS -------------*/

export enum ProjectMutationTypes {
  TOGGLE_TAB = 'TOGGLE_TAB',
  SET_PROJECT = 'SET_PROJECT',
  SET_CASE_REP = 'SET_CASE_REP',
  SET_SELECTED_CASE = 'SET_SELECTED_CASE',
  SET_HOVER_UNIT = 'SET_HOVER_UNIT',
  UPDATE_PROJECT_MINMAX = 'UPDATE_PROJECT_MINMAX',
  TOGGLE_MAP_MODE = 'TOGGLE_MAP_MODE',
  INIT_NORMALIZED_DATA_FORMAT = 'INIT_NORMALIZED_DATA_FORMAT',
  UPDATE_DATA_FORMAT = 'UPDATE_DATA_FORMAT',
  TOGGLE_SPINNER = 'TOGGLE_SPINNER',
  RESET_PROJECT_STORE = 'RESET_PROJECT_STORE',
  ADD_DOWNLOAD_QUEUE = 'ADD_DOWNLOAD_QUEUE',
  SET_DOWNLOAD_FULFILLED = 'SET_DOWNLOAD_FULFILLED',
  REMOVE_DOWNLOAD_QUEUE = 'REMOVE_DOWNLOAD_QUEUE',
  ERROR_DOWNLOAD_QUEUE = 'ERROR_DOWNLOAD_QUEUE',
  TOGGLE_CURRENT_CASE_STATUS = 'TOGGLE_CURRENT_CASE_STATUS',
  TOGGLE_DOWNLOAD_QUEUE = 'TOGGLE_DOWNLOAD_QUEUE',
  UPDATE_PRICE_FORMAT = 'UPDATE_PRICE_FORMAT',
}

/*------------ ACTIONS -------------*/

export enum ProjectActionTypes {
  GET_PROJECT = 'GET_PROJECT',
  GET_CASE_REP = 'GET_CASE_REP',
  GET_CASE_LIST = 'GET_CASE_LIST',
  QUEUE_DOWNLOAD_ITEM = 'QUEUE_DOWNLOAD_ITEM',
}
