import { parseISO } from 'date-fns'
import { wktToGeoJSON } from '@terraformer/wkt'
import { computed } from 'vue'

/* PARSE PROJECT LIST */

export const parseProjectList = (projectList) => {
  return projectList.map((project) => {
    return {
      ...project,
      created: parseISO(project.createdAt),
    }
  })
}

/* Parse Case */
export const parseCase = (caseItem, row, projectData) => {
  const overview = caseItem.overview || {}
  const price = caseItem.price || {}
  caseItem.overview = {
    ...overview,
    ...price,
    ...row,
  }
  return caseItem
}

export function applyTransform(origin, y_vec, coord: [number, number][][]) {
  const [originX, originY] = origin
  const [y_vec_x, y_vec_y] = y_vec
  const [m11, m12, m21, m22] = [y_vec_y, -y_vec_x, y_vec_x, y_vec_y]

  return coord.map((wrapper) => {
    return wrapper.map((g) => {
      const x = m11 * g[0] + m21 * g[1] + originX
      const y = m12 * g[0] + m22 * g[1] + originY
      return [x, y]
    })
  })
}

export const addUnitGeometry = (unit, unitGeom) => {
  const originPt = unit.originPt.coordinates
  const originV = unit.originVec.coordinates
  const geom = Object.keys(unitGeom).map((g) => {
    return {
      type: g,
      coordinates: applyTransform(originPt, originV, unitGeom[g].coordinates),
    }
  })

  return geom
}

/* Parse Case Geometry */
/**
 *  @param { Object } payload
 *    @param { GeoJSON } origin origin point
 *    @param { GeoJSON } y_vec y vector
 */
export const parseCaseGeom = (caseItem, from) => {
  const item = caseItem
  const { floorGeom, fixedGeom, unitGeom } = item
  // const floorGeom = Object.keys(f).reduce((p, c) => {
  //   const item = f[c]
  //   const fixed = item.fixedGeometry.map((fix) => fixedGeom[fix])
  //   // const units = item.units.map((unit) => {
  //   //   return {
  //   //     ...unit,
  //   //     geom: addUnitGeometry(unit, unitGeom[unit.unit_plan_id]),
  //   //   }
  //   // })
  //   p[c] = {
  //     ...f[c],
  //     fixedGeometry: fixed,
  //     units: item.units,
  //   }
  //   return p
  // }, {})
  return {
    ...item,
    from,
  }
}

/* WKT to GeoJson */
export const toGeoJson = (wktString: string) => {
  return wktToGeoJSON(wktString).coordinates
}
