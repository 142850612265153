import {
  moduleActionContext,
  rootActionContext,
  moduleGetterContext,
  rootGetterContext,
} from '/Store/index'

/*------------ STATE -------------*/

export type User = {
  id: number
  username: string
  nickname: string
  created?: Date
  privilege: number
  is_active: boolean
  data?: Record<string, any>
}

export interface ProjectListType {
  uuid: string;
  name: string;
  createdAt: string;
  created: Date; // custom
  status: number;
  version: number;
  address: string;
  buildingUse: string;
  profit: {
    min: number;
    max: number;
  };
  revenue: {
    min: number;
    max: number;
  };
  buildOutCost: {
    min: number;
    max: number;
  };
  far: {
    min: number;
    max: number;
  };
  landArea: number;
  gfa: {
    min: number;
    max: number;
  };
  unitCount: {
    min: number;
    max: number;
  };
}

export type userState = {
  user: User
  projects: ProjectListType[]
}

export const state: userState = {
  user: null,
  projects: [],
}

/*------------ GETTERS -------------*/

export type Getters = {
  isLoggedIn(state: userState): boolean
}

/*------------ MUTATIONS -------------*/

export enum UserMutationTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_PROJECTS = 'SET_PROJECTS',
  SET_USER = 'SET_USER',
}

/* ----------- ACTIONS ---------------*/

export enum UserActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  GET_USER = 'GET_USER',
  GET_PROJECT_LIST = 'GET_PROJECT_LIST',
}
