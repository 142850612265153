<template>
  <div class="layout-user">
    <div class="logo">
      <img src="/img/logo_short_white.svg" alt="" />
    </div>
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.layout-user {
  width: 100%;
  height: 100%;

  .logo {
    @include absolute(left 48px top 48px)
  }
}
</style>
